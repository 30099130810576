import React from "react"

const LinkedIn = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2222 2C16.6937 2 17.1459 2.1873 17.4793 2.5207C17.8127 2.8541 18 3.30628 18 3.77778V16.2222C18 16.6937 17.8127 17.1459 17.4793 17.4793C17.1459 17.8127 16.6937 18 16.2222 18H3.77778C3.30628 18 2.8541 17.8127 2.5207 17.4793C2.1873 17.1459 2 16.6937 2 16.2222V3.77778C2 3.30628 2.1873 2.8541 2.5207 2.5207C2.8541 2.1873 3.30628 2 3.77778 2H16.2222ZM15.7778 15.7778V11.0667C15.7778 10.2981 15.4725 9.56107 14.929 9.01763C14.3856 8.47419 13.6485 8.16889 12.88 8.16889C12.1244 8.16889 11.2444 8.63111 10.8178 9.32444V8.33778H8.33778V15.7778H10.8178V11.3956C10.8178 10.7111 11.3689 10.1511 12.0533 10.1511C12.3834 10.1511 12.6999 10.2822 12.9333 10.5156C13.1667 10.749 13.2978 11.0655 13.2978 11.3956V15.7778H15.7778ZM5.44889 6.94222C5.84495 6.94222 6.22478 6.78489 6.50484 6.50484C6.78489 6.22478 6.94222 5.84495 6.94222 5.44889C6.94222 4.62222 6.27556 3.94667 5.44889 3.94667C5.05047 3.94667 4.66838 4.10494 4.38666 4.38666C4.10494 4.66838 3.94667 5.05047 3.94667 5.44889C3.94667 6.27556 4.62222 6.94222 5.44889 6.94222ZM6.68444 15.7778V8.33778H4.22222V15.7778H6.68444Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LinkedIn
