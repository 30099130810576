import React from "react"

const Twitter = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3802_17374"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="14"
        height="14"
      >
        <path d="M3 3H17V17H3V3Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3802_17374)">
        <path
          d="M14.025 3.65576H16.172L11.482 9.02976L17 16.3438H12.68L9.294 11.9088L5.424 16.3438H3.275L8.291 10.5938L3 3.65676H7.43L10.486 7.70976L14.025 3.65576ZM13.27 15.0558H14.46L6.78 4.87676H5.504L13.27 15.0558Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default Twitter
