import React from "react"
import { Link as GatsbyLink, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Link, Heading, Text } from "@chakra-ui/react"

import LinkedIn from "./icons/linked-in"
import Twitter from "./icons/twitter"

const styles = {
  root: {
    width: "100%",
    height: "100%",
    color: "white",
    p: "14px",
  },
  container: {
    maxWidth: { base: "1752px" },
    mx: "auto",
    p: "0px 24px",
  },
  "wrapper-box": {
    bg: "#1D273F",
    borderRadius: "12px",
    p: "96px 0 48px",
  },
  "inner-box": {
    display: "grid",
    gridTemplateColumns: { base: "1fr", md: "1fr 1fr", xl: "1fr 1fr 1fr 1fr"},
    gap: { base: "100px", xl: "36px"},
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  "header-box": {
    flexDirection: { base: "column", md: "column", lg: "row" },
    height: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  "social-box": {
    width: "204px",
    height: "100%",
    gap: "12px",
  },
  "menu-box": {
    flexDirection: "column",
    height: "100%",
    lineHeight: "1.14",
  },
  "menu-title": {
    fontFamily: `"Lora", serif`,
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#F1F1F1",
    mb: "8px",
  },
  "menu-link": {
    fontWeight: "normal",
    fontSize: "16px",
    letterSpacing: "0.0015em",
    color: "#F1F1F1",
    mt: "24px",
    _hover: {
      color: "#E3F97B",
    },
  },
  "links-box": {
    flexDirection: { base: "column", md: "column", lg: "row" },
    width: "100%",
    height: "100%",
  },
  "copyright": {
    textAlign: "center",
    fontSize: "14px",
    pt: "46px",
    mt: { base: "46px", xl: "80px"},
    borderTop: "1px solid #FFFFFF",
  },
  "social-links": {
    bg: "#E3F97B",
    height: "36px",
    width: "36px",
    flexShrink: "0",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#1D273F",
    _hover: {
      bg: "#FAFFE1",
      color: "#1D273F",
    }
  },
  "header-col-text": {
    fontFamily: `"Lora", serif`,
    fontWeight: "500",
    fontSize: "20px",
  },
  "logo-img": {
    width: "177px"
  }
}

const navigateToHome = () => navigate("/")

const Footer = () => (
  <Box sx={styles.root}>
    <Box sx={styles["wrapper-box"]}>
      <Box sx={styles.container}>
        <Flex sx={styles["inner-box"]}>
          <Flex sx={styles["header-box"]}>
            <Flex
              sx={{
                flexDirection: "column",
                alignItems: "center",
                width: "auto",
              }}
            >
              <Box sx={styles.logo}>
                <Box sx={styles["logo-img"]}>
                  <StaticImage
                    src="../images/logo-white-new.png"
                    alt="Avant-garde Health logo"
                    onClick={navigateToHome}
                    placeholder="blurred"
                  />
                </Box>
                <Box>
                  <Text sx={styles["header-col-text"]}>Data-driven accountability:</Text>
                  <Text sx={styles["header-col-text"]}>optimize healthcare operations</Text>
                </Box>
                <Flex sx={styles["social-box"]}>
                  <Link
                    sx={styles["social-links"]}
                    href="https://twitter.com/avantgarde"
                    isExternal
                  >
                    <Twitter />
                  </Link>
                  <Link
                    sx={styles["social-links"]}
                    href="https://www.linkedin.com/company/avant-garde-health"
                    isExternal
                  >
                    <LinkedIn />
                  </Link>
                </Flex>
              </Box>
            </Flex>
          </Flex>
          <Flex sx={styles["menu-box"]}>
            <Heading sx={styles["menu-title"]}>Our Company</Heading>
            <Link as={GatsbyLink} sx={styles["menu-link"]} to="/">
              Home
            </Link>
            <Link as={GatsbyLink} sx={styles["menu-link"]} to="/about-us/">
              About Us
            </Link>
            <Link as={GatsbyLink} sx={styles["menu-link"]} to="/news/">
              News
            </Link>
            <Link as={GatsbyLink} sx={styles["menu-link"]} to="/blog/">
              Blog
            </Link>
            <Link as={GatsbyLink} sx={styles["menu-link"]} to="/our-team/">
              Our Team
            </Link>
            <Link as={GatsbyLink} sx={styles["menu-link"]} to="/careers/">
              Careers
            </Link>
            <Link as={GatsbyLink} sx={styles["menu-link"]} to="/privacy-policy/">
              Privacy Policy
            </Link>
            <Link
              sx={styles["menu-link"]}
              href="https://trust.avantgardehealth.com/"
              isExternal
            >
              Trust
            </Link>
          </Flex>
          <Flex sx={styles["menu-box"]}>
            <Heading sx={styles["menu-title"]}>Solutions & Services</Heading>
            <Link as={GatsbyLink} sx={styles["menu-link"]} to="/caremeasurement/">
              Surgical Cost Management
            </Link>

            <Link
              as={GatsbyLink}
              sx={styles["menu-link"]}
              to="/surgical-coding-optimization/"
            >
              Surgical Coding Optimization
            </Link>
            <Link as={GatsbyLink} sx={styles["menu-link"]} to="/eras/">
              Surgical Quality and Outcomes Optimization
            </Link>
            <Link
              as={GatsbyLink}
              to="/pacu-and-or-throughput/"
              sx={styles["menu-link"]}
            >
              PACU and OR Throughput
            </Link>
            <Link as={GatsbyLink} sx={styles["menu-link"]} to="/bpci-advanced/">
              BPCI Management
            </Link>
          </Flex>
          <Flex sx={styles["menu-box"]}>
            <Heading sx={styles["menu-title"]}>Research</Heading>

            <Link
              as={GatsbyLink}
              sx={styles["menu-link"]}
              to="/best-surgeons-and-hospitals-research-all-stars/"
            >
              2024 Healthcare Research All-Stars
            </Link>
            <Link as={GatsbyLink} sx={styles["menu-link"]} to="/publications/">
              Research and Publications
            </Link>
            <Link
              as={GatsbyLink}
              sx={styles["menu-link"]}
              to="/vbhc-research-group/"
            >
              VBHC Research Group
            </Link>
          </Flex>
        </Flex>
        <Box sx={styles.copyright}>
          © Copyright {new Date().getFullYear()}, All Rights Reserved by
          Avant-garde Health
        </Box>
      </Box>
    </Box>
  </Box>
)

export default Footer
