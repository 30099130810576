import React from "react"

const DownChev = () => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.4375 0.21875L4.5 3.28125L7.5625 0.21875L8.5 1.15625L4.5 5.15625L0.5 1.15625L1.4375 0.21875Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DownChev
