import React, { useState } from "react"
import {
  Box,
  Button,
  Link,
  Accordion,
  AccordionPanel,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  Flex,
} from "@chakra-ui/react"
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons"
import { Link as GatsbyLink, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"

const styles = {
  "menu-button": {
    display: {
      base: "flex",
      md: "flex",
      xl: "none",
    },
    fontSize: "16px",
    fontWeight: "normal",
    outline: "none",
    border: "none",
    bg: "transparent",
    mr: "24px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#FFFFFF",
    filter: "brightness(0) invert(1)",
  },
  "menu-list": {
    display: {
      base: "flex",
      md: "flex",
      xl: "none",
    },
    zIndex: "3",
    mt: "11px",
    padding: 0,
    minWidth: "350px",
    width: "100%",
    borderRadius: "0px",
    height: "100%",
    bg: "#F2F2F2",
  },
  accordion: {
    width: "100%",
    border: "none",
    mt: "28px",
  },
  "accordion-item": {
    width: "100%",
    border: "none",
    py: 2,
  },
  "accordion-button": {
    px: "24px 0",
  },
  "accordion-box": {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#FFFFFF",
  },
  "accordion-icon": {
    color: "#FFFFFF",
  },
  "accordion-panel": {
    py: "8px",
  },
  "close-icon": {
    color: "rgba(0, 0, 0, 0.54)",
  },
  "hamburger-icon": {
    color: "#041424",
  },
  "menu-item": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: "0.15px",
    height: "36px",
    display: "flex",
    alignItems: "center",
  },
  link: {
    color: "#FFFFFF",
    display: "block",
    "&:hover,&:active,&:focus": {
      color: "#E3F97B",
    },
  },
  button: {
    width: "100%",
    height: "52px",
    bg: "#E3F97B",
    padding: "12px",
    borderRadius: "4px",
    color: "#134440",
    fontSize: "16px",
    lineHeight: "175%",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#FFFFFF",
      outline: "none",
      borderColor: "#FFFFFF",
    },
  },
  "login-button": {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "12px",
    height: "52px",
    border: "1px solid #FFFFFF",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontSize: "16px",
    lineHeight: "24px",
    outline: "none",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    "&:hover,&:active,&:focus": {
      bg: "#E3F97B",
      borderColor: "#E3F97B",
      outline: "none",
      color: "#134440",
    },
  },
  "sidebar-box": {
    display: { base: "block", xl: "none" },
  },
  sidebar: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontWeight: "300",
    position: "fixed",
    inset: "0",
    bg: "#033F47",
    zIndex: "999",
    p: "20px",
    overflowY: "auto",
    transition: "all 0.3s linear",
    height: "100dvh",
  },
  "sidebar-header": {
    justifyContent: "space-between",
    alignItems: "center",
  },
  "sidebar-toggler": {
    color: "#FFFFFF",
    p: 0,
    bg: "transparent",
    "&:hover,&:active,&:focus": {
      bg: "transparent",
    },
  },
}

const navigateToContactUs = () => navigate("/contact-us/")

const MobileMenu = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const location = useLocation()

  const handleToggleSidebar = () => {
    setIsSidebarOpen(prev => {
      const newState = !prev

      if (newState) {
        document.body.style.overflow = "hidden"
      } else {
        document.body.style.overflow = ""
      }

      return newState
    })
  }

  return (
    <Box sx={styles["sidebar-box"]}>
      <Button
        sx={styles["sidebar-toggler"]}
        fontSize="24px"
        onClick={handleToggleSidebar}
      >
        <HamburgerIcon />
      </Button>
      <Box
        sx={{
          ...styles["sidebar"],
          transform: isSidebarOpen ? "translateX(0)" : "translateX(-103%)",
        }}
      >
        <Flex sx={styles["sidebar-header"]}>
          <Link sx={styles.logo} href={"/"}>
            <StaticImage
              src="../../images/logo-white.svg"
              alt="Avant-garde Health logo"
              placeholder="blurred"
            />
          </Link>
          <Button sx={styles["sidebar-toggler"]} onClick={handleToggleSidebar}>
            <CloseIcon />
          </Button>
        </Flex>
        <Accordion
          defaultIndex={[0, 1, 2, 3]}
          allowMultiple
          allowToggle
          sx={styles.accordion}
        >
          <AccordionItem sx={styles["accordion-item"]}>
            <AccordionButton sx={styles["accordion-button"]}>
              <Box flex="1" textAlign="left" sx={styles["accordion-box"]}>
                <Link
                  as={GatsbyLink}
                  to="/about-us/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/about-us/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                >
                  Why Avant-garde Health
                </Link>
              </Box>
            </AccordionButton>
          </AccordionItem>
          <AccordionItem sx={styles["accordion-item"]} collapsible>
            <AccordionButton sx={styles["accordion-button"]}>
              <Box flex="1" textAlign="left" sx={styles["accordion-box"]}>
                Solutions and Services
              </Box>
              <AccordionIcon sx={styles["accordion-icon"]} />
            </AccordionButton>
            <AccordionPanel sx={styles["accordion-panel"]}>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/caremeasurement/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/caremeasurement/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                >
                  Surgical Cost Management
                </Link>
              </Box>

              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/surgical-coding-optimization/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/surgical-coding-optimization/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                >
                  Surgical Coding Optimization
                </Link>
              </Box>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/eras/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/eras/" ? "#E3F97B" : "#FFFFFF",
                  }}
                >
                  Surgical Quality and Outcomes Optimization
                </Link>
              </Box>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/pacu-and-or-throughput/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/pacu-and-or-throughput/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                >
                  PACU and OR Throughput
                </Link>
              </Box>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/bpci-advanced/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/bpci-advanced/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                >
                  BPCI Management
                </Link>
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem sx={styles["accordion-item"]}>
            <AccordionButton sx={styles["accordion-button"]}>
              <Box flex="1" textAlign="left" sx={styles["accordion-box"]}>
                Research
              </Box>
              <AccordionIcon sx={styles["accordion-icon"]} />
            </AccordionButton>
            <AccordionPanel sx={styles["accordion-panel"]}>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/best-surgeons-and-hospitals-research-all-stars/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname ===
                      "/best-surgeons-and-hospitals-research-all-stars/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                >
                  2024 Healthcare Research All-Stars
                </Link>
              </Box>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/publications/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/publications/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                >
                  Research and Publications
                </Link>
              </Box>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/vbhc-research-group/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/vbhc-research-group/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                >
                  VBHC Research Group
                </Link>
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem sx={styles["accordion-item"]}>
            <AccordionButton sx={styles["accordion-button"]}>
              <Box flex="1" textAlign="left" sx={styles["accordion-box"]}>
                Our Company
              </Box>
              <AccordionIcon sx={styles["accordion-icon"]} />
            </AccordionButton>
            <AccordionPanel sx={styles["accordion-panel"]}>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/about-us/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/about-us/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                >
                  About Us
                </Link>
              </Box>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/news/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/news/" ? "#E3F97B" : "#FFFFFF",
                  }}
                >
                  News
                </Link>
              </Box>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/blog/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/blog/" ? "#E3F97B" : "#FFFFFF",
                  }}
                >
                  Blog
                </Link>
              </Box>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/our-team/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/our-team/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                >
                  Our Team
                </Link>
              </Box>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/careers/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/careers/" ? "#E3F97B" : "#FFFFFF",
                  }}
                >
                  Careers
                </Link>
              </Box>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  to="/privacy-policy/"
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/privacy-policy/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                >
                  Privacy Policy
                </Link>
              </Box>
              <Box sx={styles["menu-item"]}>
                <Link
                  as={GatsbyLink}
                  sx={{
                    ...styles.link,
                    color:
                      location.pathname === "/privacy-policy/"
                        ? "#E3F97B"
                        : "#FFFFFF",
                  }}
                  href="https://trust.avantgardehealth.com/"
                  isExternal
                >
                  Trust
                </Link>
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <Box py={2}>
            <Link
              href="https://caremeasurement.com"
              isExternal
              sx={styles["login-button"]}
            >
              Login
            </Link>
          </Box>
          <Box py={2}>
            <Button onClick={navigateToContactUs} sx={styles.button}>
              Schedule a Demo
            </Button>
          </Box>
        </Accordion>
      </Box>
    </Box>
  )
}

export default MobileMenu
