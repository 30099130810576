import React from "react"
import PropTypes from "prop-types"
import { chakra } from "@chakra-ui/react"
import Seo from "./seo"
import Navigation from "./navigation"
import NavigationOld from "./navigation/index-old"
import Footer from "./footer"
import FooterOld from "./footer-old"
import "@fontsource/inter"
import "@fontsource/roboto"
import "./variables.css"
import "./global.css"

const styles = {
  main: {
    width: "100%",
    height: "100%",
  },
}

class Template extends React.Component {
  render(props) {
    const {
      children,
      hideNavigation,
      title,
      description,
      image,
      keywords,
      location,
    } = this.props

    return (
      <>
        <Seo
          title={title}
          description={description}
          image={image}
          keywords={keywords}
        />
        {location.pathname === "/" ? (
          <Navigation hideMenu={hideNavigation} />
        ) : (
          <NavigationOld hideMenu={hideNavigation} />
        )}
        <chakra.main sx={styles.main}>{children}</chakra.main>
        {!hideNavigation && (
          <>{location.pathname === "/" ? <Footer /> : <FooterOld />}</>
        )}
      </>
    )
  }
}

Template.propTypes = {
  hideNavigation: PropTypes.bool,
}

export default Template
