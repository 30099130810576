import React from "react"
import { Flex, Button, Link } from "@chakra-ui/react"
import { Link as GatsbyLink, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import DropdownMenu from "../dropdown-menu"

const styles = {
  menu: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontWeight: "300",
    display: {
      base: "none",
      md: "none",
      xl: "flex",
    },
    height: "56px",
    alignItems: "center",
    gap: "24px",
  },
  link: {
    color: "#F8F8F8",
    fontSize: "15px",
    fontWeight: "400",
    _hover: {
      color: "#E3F97B",
    },
  },
  "dropdown-menu": {
    "menu-button": {
      color: "#F8F8F8",
      ml: "10px",
      fontSize: "15px",
      _hover: {
        color: "#E3F97B",
      },
    },
    "menu-list": {
      height: "auto",
    },
    link: {
      color: "#333333",
    },
  },
  button: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    padding: "12px 24px",
    height: "52px",
    bg: "#E3F97B",
    borderRadius: "4px",
    color: "#1D273F !important",
    fontSize: "15px",
    lineHeight: "1.4",
    outline: "none",
    fontWeight: "400",
    alignItems: { xl: "center" },
    display: {
      base: "none",
      md: "none",
      xl: "flex",
    },
    "&:hover,&:active,&:focus": {
      bg: "#FAFFE1",
      borderColor: "#FAFFE1",
      outline: "none",
      border: "none",
    },
  },
  "login-button": {
    fontFamily: `"Merriweather Sans", sans-serif`,
    padding: "12px 24px",
    height: "52px",
    bg: "transparent",
    border: "1px solid #FFFFFF",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontSize: "15px",
    lineHeight: "1.4",
    outline: "none",
    alignItems: { xl: "center" },
    display: {
      base: "none",
      md: "none",
      xl: "flex",
    },
    "&:hover,&:active,&:focus": {
      bg: "#E3F97B",
      borderColor: "#E3F97B",
      outline: "none",
      color: "#1D273F",
    },
  },
  buttonMenu: {
    display: "flex",
    gap: "24px",
  },
  menuLink: {
    display: "block",
    p: "16px 24px",
    "&:hover,&:focus": {
      bg: "#E3F97B",
    },
    width: "100%",
  },
}

const navigateToContactUs = () => navigate("/contact-us/")

const DesktopMenu = () => {
  const location = useLocation()

  return (
    <>
      <Flex sx={styles.menu}>
        <Link
          as={GatsbyLink}
          to="/about-us/"
          sx={{
            ...styles.link,
            color: location.pathname === "/about-us/" ? "#FFFFFF" : "#F8F8F8",
          }}
        >
          Why Avant-garde Health
        </Link>
        <DropdownMenu
          styles={styles["dropdown-menu"]}
          title="Solutions and Services"
        >
          <Link
            as={GatsbyLink}
            to="/caremeasurement/"
            sx={{
              ...styles.menuLink,
              bg:
                location.pathname === "/caremeasurement/"
                  ? "#E3F97B"
                  : "transparent",
            }}
          >
            Surgical Cost Management
          </Link>
          <Link
            as={GatsbyLink}
            to="/surgical-coding-optimization/"
            sx={{
              ...styles.menuLink,
              bg:
                location.pathname === "/surgical-coding-optimization/"
                  ? "#E3F97B"
                  : "transparent",
            }}
          >
            Surgical Coding Optimization
          </Link>
          <Link
            as={GatsbyLink}
            to="/eras/"
            sx={{
              ...styles.menuLink,
              bg: location.pathname === "/eras/" ? "#E3F97B" : "transparent",
            }}
          >
            Surgical Quality and Outcomes Optimization
          </Link>
          <Link
            as={GatsbyLink}
            to="/pacu-and-or-throughput/"
            sx={{
              ...styles.menuLink,
              bg:
                location.pathname === "/pacu-and-or-throughput/"
                  ? "#E3F97B"
                  : "transparent",
            }}
          >
            PACU and OR Throughput
          </Link>
          <Link
            as={GatsbyLink}
            to="/bpci-advanced/"
            sx={{
              ...styles.menuLink,
              bg:
                location.pathname === "/bpci-advanced/"
                  ? "#E3F97B"
                  : "transparent",
            }}
          >
            BPCI Management
          </Link>
        </DropdownMenu>
        <DropdownMenu styles={styles["dropdown-menu"]} title="Research">
          <Link
            as={GatsbyLink}
            to="/best-surgeons-and-hospitals-research-all-stars/"
            sx={{
              ...styles.menuLink,
              bg:
                location.pathname ===
                "/best-surgeons-and-hospitals-research-all-stars/"
                  ? "#E3F97B"
                  : "transparent",
            }}
          >
            2024 Healthcare Research All-Stars
          </Link>
          <Link
            as={GatsbyLink}
            to="/publications/"
            sx={{
              ...styles.menuLink,
              bg:
                location.pathname === "/publications/"
                  ? "#E3F97B"
                  : "transparent",
            }}
          >
            Research and Publications
          </Link>
          <Link
            as={GatsbyLink}
            to="/vbhc-research-group/"
            sx={{
              ...styles.menuLink,
              bg:
                location.pathname === "/vbhc-research-group/"
                  ? "#E3F97B"
                  : "transparent",
            }}
          >
            VBHC Research Group
          </Link>
        </DropdownMenu>
        <DropdownMenu styles={styles["dropdown-menu"]} title="Our Company">
          <Link
            as={GatsbyLink}
            to="/about-us/"
            sx={{
              ...styles.menuLink,
              bg:
                location.pathname === "/about-us/" ? "#E3F97B" : "transparent",
            }}
          >
            About Us
          </Link>
          <Link
            as={GatsbyLink}
            to="/news/"
            sx={{
              ...styles.menuLink,
              bg: location.pathname === "/news/" ? "#E3F97B" : "transparent",
            }}
          >
            News
          </Link>
          <Link
            as={GatsbyLink}
            to="/blog/"
            sx={{
              ...styles.menuLink,
              bg: location.pathname === "/blog/" ? "#E3F97B" : "transparent",
            }}
          >
            Blog
          </Link>
          <Link
            as={GatsbyLink}
            to="/our-team/"
            sx={{
              ...styles.menuLink,
              bg:
                location.pathname === "/our-team/" ? "#E3F97B" : "transparent",
            }}
          >
            Our Team
          </Link>
          <Link
            as={GatsbyLink}
            to="/careers/"
            sx={{
              ...styles.menuLink,
              bg: location.pathname === "/careers/" ? "#E3F97B" : "transparent",
            }}
          >
            Careers
          </Link>
          <Link
            as={GatsbyLink}
            to="/privacy-policy/"
            sx={{
              ...styles.menuLink,
              bg:
                location.pathname === "/privacy-policy/"
                  ? "#E3F97B"
                  : "transparent",
            }}
          >
            Privacy Policy
          </Link>
          <Link
            as={GatsbyLink}
            sx={{
              ...styles.menuLink,
              bg:
                location.pathname === "/privacy-policy/"
                  ? "#E3F97B"
                  : "transparent",
            }}
            href="https://trust.avantgardehealth.com/"
            isExternal
          >
            Trust
          </Link>
        </DropdownMenu>
      </Flex>
      <Flex sx={styles.buttonMenu}>
        <Link
          href="https://caremeasurement.com"
          isExternal
          sx={styles["login-button"]}
        >
          Login
        </Link>
        <Link
          href="https://avantgardehealth.com/schedule-a-demo"
          isExternal
          sx={styles.button}
        >
          Schedule a Demo
        </Link>
      </Flex>
    </>
  )
}

export default DesktopMenu
