import React from "react"
import { Box, Flex, Link, Heading } from "@chakra-ui/react"
import { Link as GatsbyLink, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const styles = {
  root: {
    width: "100%",
    height: "100%",
    bg: "#1B263F",
    px: "24px",
    py: "40px",
  },
  innerBox: {
    flexDirection: { base: "column", md: "column", lg: "row" },
    width: "100%",
  },
  logo: {
    height: "96px",
    width: "104.02px",
    minHeight: "96px",
    minWidth: "104.02px",
    cursor: "pointer",
  },
  headerBox: {
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: "flex-start",
  },
  socialBox: {
    mt: { base: "95px", md: "95px", lg: "0px" },
    flexDirection: "column",
    alignItems: "flex-start",
    width: "204px",
    height: "100%",
    ml: { base: "0px", md: "0px", lg: "122.98px" },
  },
  menuBox: {
    mt: { base: "40px", md: "40px", lg: "0px" },
    flexDirection: "column",
    width: { base: "100%", md: "100%", lg: "22.5%" },
    ml: { base: "0%", md: "0%", lg: "2.5%" },
    height: "100%",
  },
  menuTitle: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#F1F1F1",
    mb: "16px",
  },
  menuLink: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#F1F1F1",
    mt: "8px",
  },
  linksBox: {
    flexDirection: { base: "column", md: "column", lg: "row" },
    width: "100%",
    height: "100%",
    borderLeft: { base: "none", md: "none", lg: "1px solid #F1F1F1" },
  },
}

const navigateToHome = () => navigate("/")

const FooterOld = () => (
  <Box sx={styles.root}>
    <Flex sx={styles.innerBox}>
      <Flex sx={styles.headerBox}>
        <Flex
          sx={{
            flexDirection: "column",
            alignItems: "center",
            width: "auto",
          }}
        >
          <Box sx={styles.logo} onClick={navigateToHome}>
            <StaticImage
              src="../images/logos/footer-logo.png"
              alt="Avant-garde Health logo"
              placeholder="blurred"
            />
          </Box>
        </Flex>
        <Flex sx={styles.socialBox}>
          <Heading sx={styles.menuTitle}>Social</Heading>
          <Link sx={styles.menuLink} href="https://twitter.com/avantgarde" isExternal>
            Twitter
          </Link>
          <Link sx={styles.menuLink} href="https://www.linkedin.com/company/avant-garde-health" isExternal>
            LinkedIn
          </Link>
        </Flex>
      </Flex>
      <Flex sx={styles.linksBox}>
        <Flex sx={styles.menuBox}>
          <Heading sx={styles.menuTitle}>Why Avant Garde Health</Heading>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/">
            Home
          </Link>
        </Flex>
        <Flex sx={styles.menuBox}>
          <Heading sx={styles.menuTitle}>Solutions & Services</Heading>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/caremeasurement/">
            Surgical Cost Management
          </Link>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/surgical-coding-optimization/">
            Surgical Coding Optimization
          </Link>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/eras/">
            Surgical Quality and Outcomes Optimization
          </Link>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/pacu-and-or-throughput/">
            PACU and OR Throughput
          </Link>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/bpci-advanced/">
            BPCI Management
          </Link>
        </Flex>
        <Flex sx={styles.menuBox}>
          <Heading sx={styles.menuTitle}>Research</Heading>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/best-surgeons-and-hospitals-research-all-stars/">
            2024 Healthcare Research All-Stars
          </Link>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/publications/">
            Research and Publications
          </Link>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/vbhc-research-group/">
            VBHC Research Group
          </Link>
        </Flex>
        <Flex sx={styles.menuBox}>
          <Heading sx={styles.menuTitle}>Our Company</Heading>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/about-us/">
            About Us
          </Link>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/news/">
            News
          </Link>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/blog/">
            Blog
          </Link>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/our-team/">
            Our Team
          </Link>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/careers/">
            Careers
          </Link>
          <Link as={GatsbyLink} sx={styles.menuLink} to="/privacy-policy/">
            Privacy Policy
          </Link>
        </Flex>
      </Flex>
    </Flex>
  </Box>
)

export default FooterOld
