import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Flex, Link, Box } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import DesktopMenu from "./desktop-menu"
import MobileMenu from "./mobile-menu"

const styles = {
  root: {
    borderRadius: "12px 12px 0 0",
    m: { base: "10px 10px 0", lg: "10px 14px 0" },
    bg: "#033F47",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  },
  rootScrolledUp: {
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    transform: "translateY(0)",
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: "15",
    borderRadius: 0,
    m: 0,
  },
  rootScrolledDown: {
    transform: "translateY(-100%)",
  },
  container: {
    maxWidth: { base: "1520px" },
    mx: "auto",
    p: { base: "0px 16px", lg: "0px 24px" },
  },
  nav: {
    height: "96px",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    zIndex: "3",
  },
  logo: {
    minWidth: "194px",
    width: "194px",
    cursor: "pointer",
  },
}

const SCROLL_THRESHOLD = 200 // Change this value to set the threshold

const Navigation = ({ hideMenu }) => {
  const [isScrollingUp, setIsScrollingUp] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [hasScrolledPastThreshold, setHasScrolledPastThreshold] =
    useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      // Check if we have scrolled past the threshold
      if (currentScrollY > SCROLL_THRESHOLD) {
        setHasScrolledPastThreshold(true)

        // Detect scroll direction
        if (currentScrollY < lastScrollY) {
          setIsScrollingUp(true)
        } else {
          setIsScrollingUp(false)
        }
      } else {
        setHasScrolledPastThreshold(false)
      }

      setLastScrollY(currentScrollY)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [lastScrollY])

  return (
    <Box
      sx={{
        ...styles.root,
        ...(hasScrolledPastThreshold
          ? isScrollingUp
            ? styles.rootScrolledUp
            : styles.rootScrolledDown
          : {}),
      }}
    >
      <Box sx={styles.container}>
        <Flex as="nav" aria-label="Main" sx={styles.nav}>
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link sx={styles.logo} href={"/"}>
              <StaticImage
                src="../../images/logo-white-new.png"
                alt="Avant-garde Health logo"
                placeholder="blurred"
              />
            </Link>
          </Flex>
          {!hideMenu && <DesktopMenu />}
          {!hideMenu && <MobileMenu />}
        </Flex>
      </Box>
    </Box>
  )
}

Navigation.propTypes = {
  hideMenu: PropTypes.bool,
}

export default Navigation
