import React from "react"
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react"
import { TriangleDownIcon } from "@chakra-ui/icons"
import merge from "lodash/merge"
import uniqueId from "lodash/uniqueId"
import DownChev from "./icons/cheveron-down"


const defaultStyles = {
  "menu-button": {
    height: "28px",
    width: "auto",
    fontSize: "16px",
    lineHeight: "1.4",
    letterSpacing: "0.15px",
    color: "#F8F8F8",
    fontWeight: "400",
    px: 0,
    bg: "transparent",
  },
  "menu-list": {
    padding: "16px 0",
    width: "auto",
    height: "124px",
    bg: "background",
    boxShadow:
      "0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 3px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: "12px",
  },
  "menu-item": {
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    width: "100%",
    p: "0",
    "&:hover,&:focus": {
      bg: "#E3F97B",
    },
    "> a": {
      color: "#333333 !important",
    }
  },
}

const DropdownMenu = ({ title, children, styles }) => {
  const _styles = merge({}, defaultStyles, styles)
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={
          <DownChev />
        }
        sx={_styles["menu-button"]}
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        _expanded={{ bg: "transparent" }}
      >
        {title}
      </MenuButton>
      <MenuList sx={_styles["menu-list"]}>
        {React.Children.map(children, child => (
          <MenuItem key={uniqueId("menu-item")} sx={_styles["menu-item"]}>
            {child}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

DropdownMenu.defaultProps = {
  styles: {},
}

export default DropdownMenu
